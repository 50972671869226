import { GetStaticProps } from 'next';
import { GetHome } from '../src/queries/GetHome';

import Home, { HomeProps } from '../src/templates/Home/Home';

export default function Index(props: HomeProps) {
	return <Home {...props} />;
}

export async function getStaticProps({
	locale,
}: GetStaticProps & { locale: string }) {
	const { props, ...rest } = await GetHome({
		locale: locale,
		slug: '/',
	});

	return {
		...rest,
		props: {
			...props,
		},
	};
}
