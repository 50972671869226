import { VisuallyHidden } from 'reakit/VisuallyHidden';
import useTranslation from 'next-translate/useTranslation';

import { Grid } from '@keahotels/components';

import Image from '../../components/Image/Image';
import Link from '../../components/Link/Link';
import HotelBookingToolbar from '../../components/HotelBookingToolbar/HotelBookingToolbar';
import Blocks from '../../components/Blocks/Blocks';

import getHotelClassName from '../../utils/getHotelClassName';
import isNotNull from '../../utils/isNotNull';

import styles from './Home.module.css';

import { ReactComponent as HomeMap } from '@keahotels/assets/images/home-page-map.svg';

import MainHeader from '../../components/MainHeader/MainHeader';
import {
	GetHome,
	GetHome_page_PageHome_hotels_hotel_header_slider,
} from '../../queries/__generated__/GetHome';
import Video from '../../components/Video/Video';

export type HomeProps = GetHome;

type HotelImageBlockProps = {
	id: number;
	title: string | null;
	url: string;
	slider?: GetHome_page_PageHome_hotels_hotel_header_slider | null;
	i: number;
	image?: {
		src: string;
	};
	external?: boolean;
};
const HotelImageBlock = ({
	id,
	title,
	url,
	slider,
	image,
	i,
	external,
}: HotelImageBlockProps) => {
	let imageEl: any = image || null;
	let video: any = null;
	if (slider?.__typename === 'MainHeaderImage') {
		imageEl = slider.image;
	} else if (slider?.__typename === 'MainHeaderImageSlider') {
		imageEl = slider.images[0].image;
	} else if (slider?.__typename === 'MainHeaderVideo') {
		video = slider.video;
	}

	const Box = external ? 'a' : Link;
	return (
		<Box
			style={{ background: '#ddd', position: 'relative' }}
			className={getHotelClassName(id)}
			href={url}
			target={external ? '_blank' : undefined}
		>
			<VisuallyHidden>{title}</VisuallyHidden>

			{imageEl && (
				<Image
					key={id}
					aspectRatio={i === 0 || i === 5 ? 'square' : 'landscape'}
					hotelOverlay={id}
					priority={i < 2}
					{...imageEl}
				/>
			)}
			{video && (
				<Video
					key={id}
					aspectRatio={i === 0 || i === 5 ? 'square' : 'landscape'}
					hotelOverlay={id}
					src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/wp-content/uploads/${video.uri}`}
					autoPlay
					loop
					muted
					playsInline
				/>
			)}
		</Box>
	);
};

export default function Home({ page }: HomeProps) {
	const { t, lang } = useTranslation('common');
	if (!page) return null;

	const hotels =
		page.__typename === 'PageHome'
			? page.hotels.map(({ hotel }) => hotel).filter(isNotNull)
			: [];
	const header = page.__typename === 'PageHome' ? page.header : null;
	const menu = page.__typename === 'PageHome' ? page.menu : null;

	const { title, intro, blocks } = page;

	return (
		<>
			<HotelBookingToolbar menu={menu} />
			{/* @ts-ignore */}
			<MainHeader {...header}>
				<div className={styles.introSection}>
					<HomeMap className={styles.homeMap} />
					<div className={styles.intro}>
						<h1
							className="text-large-body"
							dangerouslySetInnerHTML={{
								__html: t('homePageIntro'),
							}}
						/>
					</div>
				</div>
			</MainHeader>

			<Grid as="section" className={styles.hotelList}>
				{hotels.map(({ id, header, title, uri }, i) => {
					return (
						<HotelImageBlock
							key={id}
							id={id || 0}
							i={i}
							title={title}
							external={id && [9879].includes(id) ? true : false}
							url={
								id && [9879].includes(id)
									? lang === 'is'
										? `https://grimsborgir.is`
										: `https://grimsborgir.com`
									: uri
							}
							slider={header?.slider}
						/>
					);
				})}
			</Grid>
			<Blocks blocks={blocks} />
		</>
	);
}
